import React from 'react';
import './App.css';

function App() {
  return (
    <div className='root-content'>
      <h1>koo-we</h1>
    </div>
  );
}

export default App;
